
#main-content{
    background-color:#D5D9E2;
    height: 100%;
}
.image{    
    width: 600px;
}

.banner{
    width: 600px;
    margin-top: 15%;
    margin-bottom: 10%;
}
.logo{
    width: 170px;
}
.boxstly{
    display: block;
    background-color: #fff;
    border-radius: 20px;
    /* border: 1px solid; */
    box-shadow: 0ch;
    margin-top: 80px;
    /* margin-bottom: 10%; */
    box-shadow:  0px 52px 80px 5px #888888;
}
.purple{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px ;
}
.form{
    border: none !important;
    
}